import axios from "axios"
import router from "../router/index.js"
import { useUserStore } from "@/stores/User.js"

const baseURL = import.meta.env.VITE_PUBLIC_API
const withCredentials = true

export default function API(intercept: boolean = true) {
  const API = axios.create({
    baseURL: baseURL,
    withCredentials: withCredentials
  })

  if (intercept) {
    API.interceptors.response.use((response) => {
      return response
    }, async (err) => {
      if (err.response && err.response.status === 401) {
        await useUserStore().signOut()
        return Promise.reject(await router.push({
          name: "SignIn"
        }))
      } else {
        console.error(err)
        return Promise.reject(err)
      }
    })
  }

  return API
}
